import { t } from "../intl";
import { ApiService } from "./ApiService";

export type TAuthTokenRequest = { phone: string } | { email: string };

export class CasService extends ApiService {
  protected apiPrefix = `/cas/api`;

  generateAndSendAuthToken = (data: TAuthTokenRequest) => {
    return this.put("auth/token", JSON.stringify(data)).catch(() => {
      throw new Error(t("errorPage.unexpectedError.desc"));
    });
  };
}
