import { ApiService } from "./ApiService";
import { IFormDataState } from "../providers/FormDataContext";
import { Buffer } from "buffer";
import { IFormGlobalConfig } from "../constants/ConfigTypes";
import { IUploadResponse } from "../models/uploadResponse";
import { t } from "../intl";

export class DocsService extends ApiService {
  getConfig = () => {
    return this.get<IFormGlobalConfig>("form/config").catch(() => {
      throw new Error(t("errorPage.unexpectedError.desc"));
    });
  };

  uploadDocuments = (formDataState: IFormDataState) => {
    const formData = new FormData();

    formDataState.files.forEach((file) => formData.append("files", file));

    formData.append(
      "form",
      new Blob(
        [
          JSON.stringify({
            firstname: formDataState.firstname,
            lastname: formDataState.lastname,
            category: formDataState.category,
            topic: formDataState.topic,
            policyNo: formDataState.policyNo,
            contact: {
              email: formDataState.contact.email,
              phone: formDataState.contact.phone,
            },
            description: formDataState.description,
          }),
        ],
        { type: "application/json" }
      )
    );

    const { phone, email } = formDataState.contact;
    const authKey = Buffer.from(`${phone || email}:${formDataState.code}`).toString("base64");
    const headers = new Headers({ Authorization: `Basic ${authKey}` });

    return this.post<IUploadResponse>("upload", formData, headers).catch(() => {
      throw new Error(t("errorPage.unexpectedError.upload.desc"));
    });
  };
}
